import React, { useEffect, useState } from "react";
import { Button, Table } from "tabler-react";
import { onValue, limitToLast, orderByChild, query } from "firebase/database";

import { turfersRef } from "../../Services/FirebaseDB";
import { useSupporters } from "../../Hooks/useSupporters";
import Username from "../Username";
import { FirebaseUserData } from "../../Types/UserData";

const INIT_LIMIT = 10;
const LIMIT_STEP = 20;
const MAX_LIMIT = 100;

type TurfersMap = Record<string, FirebaseUserData>;

const TopTurfersRegion: React.FC = () => {
  const [turfers, setTurfers] = useState<FirebaseUserData[]>([]);
  const [limit, setLimit] = useState(INIT_LIMIT);
  const supporters = useSupporters();

  const showMore = () => {
    setLimit((prevLimit) => Math.min(prevLimit + LIMIT_STEP, MAX_LIMIT));
  };

  useEffect(() => {
    const turfersTopRef = query(
      turfersRef,
      orderByChild("regionsVisited"),
      limitToLast(limit)
    );

    const unsub = onValue(turfersTopRef, (snapshot) => {
      const data: TurfersMap = snapshot.val();
      if (!data) {
        setTurfers([]);
        return;
      }
      const sortedList = Object.values(data).sort((a, b) =>
        (a.regionsVisited ?? 0) < (b.regionsVisited ?? 0) ? 1 : -1
      );
      setTurfers(Object.values(sortedList));
    });

    return () => unsub();
  }, [limit]);

  return (
    <div>
      <h3>
        Top Turfers - <em>Regions Visited</em>
      </h3>

      <Table
        cards={true}
        striped={true}
        responsive={true}
        className="table-vcenter"
      >
        <Table.Header>
          <Table.Row>
            <Table.ColHeader>#</Table.ColHeader>
            <Table.ColHeader>Name</Table.ColHeader>
            <Table.ColHeader>
              <strong>Regions</strong>
            </Table.ColHeader>
            <Table.ColHeader>TM Score</Table.ColHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {turfers.map((t, index) => (
            <Table.Row key={t.name}>
              <Table.Col>{index + 1}</Table.Col>
              <Table.Col>
                <Username username={t.name} supporters={supporters} />
              </Table.Col>
              <Table.Col>
                <strong>{t.regionsVisited ?? "-"}</strong>
              </Table.Col>
              <Table.Col>
                {t.totalScore
                  ? `${Math.round(t.totalScore * 100 * 100) / 100}%`
                  : "-"}
              </Table.Col>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {limit < MAX_LIMIT && (
        <div className="m-4">
          <Button onClick={showMore}>Show more</Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(TopTurfersRegion);
