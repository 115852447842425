import React, { useEffect } from "react";
import { Card, Grid } from "tabler-react";

import TopTurfers from "../Components/Top/TopTurfers";
import TopGraphs from "../Components/Top/TopGraphs";
import TopTurfersRegion from "../Components/Top/TopTurfersRegion";
import TopTurfersArea from "../Components/Top/TopTurfersArea";

const Top: React.FC = () => {
  useEffect(() => {
    document.title = `Toplists - Turf Medals`;
  }, []);

  return (
    <div>
      <Card className="p-4">
        <TopTurfers />
      </Card>

      <Card className="p-4">
        <Grid.Row className="flex-grow">
          <Grid.Col sm={12} lg={6}>
            <TopTurfersRegion />
          </Grid.Col>
          <Grid.Col sm={12} lg={6}>
            <TopTurfersArea />
          </Grid.Col>
        </Grid.Row>
      </Card>
      <Card className="p-4">
        <TopGraphs />
      </Card>
    </div>
  );
};

export default React.memo(Top);
